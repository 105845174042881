import React from 'react';
import style from './myAppointments.module.scss';
import AppointmentCard from './AppointmentCard/AppointmentCard';
import AppointmentCardNoStates from './AppointmentCardNoStates/AppointmentCardNoStates';
import Button from 'components/Button/Button';
import HiddenSideSection from './HiddenSideSection/HiddenSideSection';
import SideSection from './SideSection/SideSection';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import MainSectionCarriers from 'components/MainSectionCarriers/MainSectionCarriers';
import Tabs from 'components/Tabs/Tabs';
import { concatInProgressCarriers } from 'helpers/appointments/appointments';
import axios from 'axios';
import Card from 'components/Card/Card';
import { ArrowBack, Link, MoreVert } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import DropDownMenu from 'components/DropDownMenu/DropDownMenu';
import DropDownMenuItem from 'components/DropDownMenu/DropDownMenuItem/DropDownMenuItem';
import Statuses from './statuses/Statuses';
import { useSelector } from 'react-redux';
import SideSectionUnnapointed from './SideSectionUnnapointed/SideSectionUnnapointed';
import Modal from 'components/Modal/Modal';
import companyName from 'constants/companyNames';
import getCompanyVariables from 'helpers/getCompanyVariables';

const company = getCompanyVariables();

function MyAppointmentsView({
  carriers,
  appointedCarriers,
  zohoUserData,
  getAppointmentsViewData,
  addRequestedAppointments,
  setLocalLoader,
  ...props
}) {
  //Redux
  const reqAppointments = useSelector(
    state => state.appointments.requestedAppointments
  );

  let { carriersArray } = useSelector(state => state.appointments);
  const marketplaceSortedKeys = Object.keys(carriers.Marketplace).sort();
  const [activeCarrier, setActiveCarrier] = useState({
    name: '',
    carrier: '',
    confirmed: [],
    pending: [],
    isMarketplace: false
  });
  const cancelTokenSource = axios.CancelToken.source();

  const [floatingTabs, setFloatingTabs] = useState(false);
  const [showRequestAppointmentsModal, setShowRequestAppointmentsModal] =
    useState(false);

  useEffect(() => {
    getAppointmentsViewData(setLocalLoader, cancelTokenSource,true);
    return () => {
      cancelTokenSource.cancel('API Call Canceled');
    };
  }, []);

  useEffect(() => {
    if (activeCarrier.carrier) onMarketplaceClick(activeCarrier.carrier);
  }, [reqAppointments]);

  const onMarketplaceClick = name => {
    let pending = reqAppointments[name]
    ? (reqAppointments[name].carrier === 'BCBS'
        ? reqAppointments[name].bcbsState
        : reqAppointments[name].inProgressArray)
    : [];
    let confirmed = zohoUserData[name.split(' ').join('_')] || [];
    let createdDate = reqAppointments[name]
      ? reqAppointments[name].created_time
      : '';
    let inProgressDate = reqAppointments[name]
      ? reqAppointments[name].in_progress_date
      : '';
    let agentStatus = reqAppointments[name]
      ? reqAppointments[name].agent_status
      : '';
    let username = reqAppointments[name] ? reqAppointments[name].username : '';
    let password = reqAppointments[name] ? reqAppointments[name].password : '';
    let ONeillNote = reqAppointments[name]
      ? reqAppointments[name].standard_note_for_agent
      : '';
    let comments = reqAppointments[name]
      ? reqAppointments[name].custom_note_for_agent
      : '';

    let carrierData = carriersArray.filter(
      carrierdata => carrierdata.name === name
    );
    setActiveCarrier({
      ...activeCarrier,
      name,
      pending,
      confirmed,
      carrier: name,
      createdDate,
      inProgressDate,
      agentStatus,
      username,
      password,
      ONeillNote,
      comments,
      carrierData: carrierData[0],
      statesOffered: carrierData[0].statesOffered,
      isMarketplace: marketplaceSortedKeys.includes(name)
    });
  };
  const handleResetActiveCarrier = () => {
    setActiveCarrier({
      name: '',
      carrier: '',
      confirmed: [],
      pending: [],
      isMarketplace: false
    });
  };

  const handleOpenLink = url => {
    window.open(url, 'blank');
   };

  const handleOpenVideo = () => {
    setShowRequestAppointmentsModal(true);
  };

  const handleScroll = e => {
    if (e.target.scrollTop > 120) {
      setFloatingTabs(true);
    } else {
      setFloatingTabs(false);
    }
  };

  return (
    <div
      className={`${style.myAppointments} ${
        activeCarrier.name ? style.isActive : ''
      }`}
    >
      <div className={style.myAppointmentsItem}>
        <Card
          fill
          noPadding={true}
          title='My Appointments'
          onScrollCapture={handleScroll}
          options={
            <>
              {company === 'oneill' && (
                <div className={style.buttonsContainer}>
                  <Button
                    size='small'
                    type='primaryLight'
                    rounded={'large'}
                    text={'Tutorial: How to Request An Appointment'}
                    onClick={() =>
                      handleOpenVideo()
                    }
                    className={style.button}
                  />
                  {/*
                  <Button
                    size='small'
                    type='primaryLight'
                    rounded={'large'}
                    text={'Check out our Contracting Road map!'}
                    onClick={() =>
                      handleOpenLink(
                        'https://oneillmarketing.net/wp-content/uploads/2024/07/Contracting-Road-Map-1.pdf'
                      )
                    }
                    className={style.button}
                  />
                  */}
                </div>
              )}
              <DropDownMenu
                title='More options'
                menuTrigger={
                  <Tooltip title='More options'>
                    <div>
                      <Button
                        size='medium'
                        type='transparent'
                        outlined
                        icon={<MoreVert />}
                      />
                    </div>
                  </Tooltip>
                }
              >
                <DropDownMenuItem
                  icon={<Link />}
                  text='NIPR website'
                  to='https://pdb.nipr.com/my-nipr/frontend/identify-licensee'
                  external
                />
              </DropDownMenu>
            </>
          }
        >
          <Tabs
            floatingHeader={floatingTabs}
            headers={['Appointed Carriers', 'All Carriers']}
          >
            <MainSectionCarriers
              Card={AppointmentCard}
              CardNoStates={AppointmentCardNoStates}
              carriers={concatInProgressCarriers(
                appointedCarriers,
                reqAppointments
              )}
              message={`No Appointed or Requested Carriers - Click on the All Carriers tab above to view all ${companyName.short} carriers.`}
              active={activeCarrier.name}
              setActive={onMarketplaceClick}
              header={<Statuses />}
              {...props}
            />
            <MainSectionCarriers
              Card={AppointmentCard}
              CardNoStates={AppointmentCardNoStates}
              carriers={carriers}
              active={activeCarrier.name}
              setActive={onMarketplaceClick}
              header={<Statuses />}
              {...props}
            />
          </Tabs>
        </Card>
      </div>
      {/* (activeCarrier.confirmed === true && activeCarrier.name) */}
      <div className={style.detailsItem}>
        {!activeCarrier.name ||
        (activeCarrier.confirmed.length === 0 &&
          activeCarrier.pending.length === 0 &&
          activeCarrier.createdDate === '') ? (
          activeCarrier.name ? (
            <Card
              fill
              title={`${activeCarrier.carrier} details`}
              leftOptions={
                <Tooltip title={'Close'}>
                  <div>
                    <Button
                      size='small'
                      type='default'
                      icon={<ArrowBack />}
                      rounded='large'
                      outlined
                      onClick={handleResetActiveCarrier}
                    />
                  </div>
                </Tooltip>
              }
            >
              <SideSectionUnnapointed
                {...activeCarrier}
                addRequestedAppointments={addRequestedAppointments}
                InitialOnboardingFormCompleted={
                  zohoUserData.Initial_Onboarding_Form_Completed
                }
              />
            </Card>
          ) : (
            <HiddenSideSection text='Click on any pending carriers (in yellow color) to view more information on your appointment status and progress notes for that carrier.' />
          )
        ) : (
          <Card
            fill
            title={`${activeCarrier.carrier} details`}
            leftOptions={
              <Tooltip title={'Close'}>
                <div>
                  <Button
                    size='small'
                    type='default'
                    icon={<ArrowBack />}
                    rounded='large'
                    outlined
                    onClick={handleResetActiveCarrier}
                  />
                </div>
              </Tooltip>
            }
          >
            <SideSection
              {...activeCarrier}
              addRequestedAppointments={addRequestedAppointments}
              InitialOnboardingFormCompleted={
                zohoUserData.Initial_Onboarding_Form_Completed
              }
            />
          </Card>
        )}
      </div>

      <Modal
        title={'How to Request Appointments'}
        active={showRequestAppointmentsModal}
        size='larger'
        closeModal={() => setShowRequestAppointmentsModal(false)}
      >
        <video width='100%' height='100%' controls autoPlay>
          <source
            src={
              "https://firebasestorage.googleapis.com/v0/b/o-neill-marketing-portal.appspot.com/o/O'Neill%20Portal%20-%20Appointments.mp4?alt=media&token=f8b44e88-e368-4f62-8bfc-9bdd2a1e4e8d"
            }
            type='video/mp4'
          />
        </video>
      </Modal>
    </div>
  );
}

MyAppointmentsView.propTypes = {
  carriers: propTypes.object,
  appointedCarriers: propTypes.object,
  zohoUserData: propTypes.object,
  getAppointmentsViewData: propTypes.func,
  addRequestedAppointments: propTypes.func,
  setLocalLoader: propTypes.func,
  localLoader: propTypes.bool
};

export default MyAppointmentsView;
